define("discourse/plugins/username-color-based-on-group/discourse/initializers/colorize", ["exports", "discourse/lib/plugin-api"], function (_exports, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function initColorize(api) {
    api.includePostAttributes('colorized_groups');

    // Hijacking post icons to sneak in CSS for forum posts
    api.addPosterIcons((cfs, attrs) => {
      //console.table(attrs);

      // A real icon could be specified for there by filling in the icon field (maybe that could be cool later).  
      // For now, with no icon specified, it's just for CSS highlighting of name.

      if (attrs.colorized_groups == null) return; // server is not passing parameter, nothing to do

      if (attrs.colorized_groups.indexOf("developer") > -1) return {
        icon: '',
        className: 'developer',
        title: 'Developer'
      };else if (attrs.colorized_groups.indexOf("wip_researcher") > -1) return {
        icon: '',
        className: 'wip_researcher',
        title: 'WIP Researcher'
      };else if (attrs.colorized_groups.indexOf("researcher") > -1) return {
        icon: '',
        className: 'researcher',
        title: 'Researcher'
      };
    });

    // Also colorize names in the real-time chat
    /*
    	// This method no longer works in 2025, as 'this' is not passed
    api.decorateChatMessage(function (messageContainer, chatChannel) 
    {
    	let colorized_groups = this.args?.message?.user?.colorized_groups;
    		if (colorized_groups == null)
    		return; // no groups, nothing to do
    		const nameClass = "chat-message-info__username__name";						
    		let elements = messageContainer.getElementsByClassName(nameClass);
    	if (elements.length == 0) // normal: this might be a second message that does not have the username header
    		return; 
    		let nameDiv = elements[0];
    		if (colorized_groups.indexOf("developer") > -1)
    		nameDiv.classList.add("developer");
    	else if (colorized_groups.indexOf("wip_researcher") > -1)
    		nameDiv.classList.add("wip_researcher");
    	else if (colorized_groups.indexOf("researcher") > -1)
    		nameDiv.classList.add("researcher");
    });
    */

    // This method still does though:
    api.modifyClass("component:chat/message/info", Superclass => class extends Superclass {
      get usernameClasses() {
        let classes = super.usernameClasses;

        //console.log(this.args.message.user);

        let colorized_groups = this.args?.message?.user?.colorized_groups;
        if (colorized_groups == null) return; // no groups, nothing to do

        const nameClass = "chat-message-info__username__name";

        // Add extra classes
        if (colorized_groups.indexOf("developer") > -1) classes += " developer";else if (colorized_groups.indexOf("wip_researcher") > -1) classes += " wip_researcher";else if (colorized_groups.indexOf("researcher") > -1) classes += " researcher";
        return classes;
      }
    });
  }
  var _default = _exports.default = {
    name: 'username-color-based-on-group',
    initialize(container) {
      (0, _pluginApi.withPluginApi)('0.1', initColorize);
    }
  };
});